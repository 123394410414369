import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import how1 from "../images/how_1.png"
import how2 from "../images/how_2.png"
import how3 from "../images/how_3.svg"
//import how4 from "../images/how_4.svg"
import highlight1 from "../images/ne_icin_biriktiriyorsun.svg"
import highlight2 from "../images/hep_beraber_biriktirin.svg"
import highlight3 from "../images/hayallerini_finanse_et.svg"
//import highlight4 from "../images/beklemede_kal.svg"

import '../styles/grid.scss'
import '../styles/index.scss'


/* ÇEMBERE KATIL KISMI
<div className="row colored light-blue-bg">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img src={how4} alt="Çembere Katıl"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1>Çembere Katıl</h1>
                <p>Seninle aynı hayali paylaşan kişiler daha önce benzer hedefler koyarak Çember oluşturmuş olabilir. Daha önce oluşturulanları incele, sana en uygun olanına katıl! </p>
                <img className="highlight-image" src={highlight4} alt="Çok yakında" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>
*/

const HowPage = () => {
  return(
    <Layout>
      <Head title="Nasıl Çalışır?" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Bu sayfada çember'in nasıl çalıştığını anlattık." />
      <div className="row section-title">
        
        <h1>Nasıl Biriktirirsin?</h1>
        
      </div>
      <div className="row how">
      <div className="row">
        <div className="container">
          <p className="intro">Çember, <b>altın günü</b> modeli kullanarak hayallerin için arkadaşlarınla para biriktirebileceğin bir uygulamadır. </p>
        </div>
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Çember Oluştur</h1>
                <p>Aylık biriktirmek istediğin miktarı ve bunu kaç arkadaşınla birlikte yapacağını seçerek bir çember oluştur. Hedefine uygun bir isim vermeyi de unutma.</p>
                <img className="highlight-image" src={highlight1} alt="Ne için biriktiriyorsun?" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={how1} alt="Çember Oluştur"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img src={how2} alt="Sevdiklerini Davet Et"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1>Sevdiklerini Davet Et</h1>
                <p>Birlikte biriktirmeye başlamak için <b>seninle aynı hayali paylaşan veya aynı miktarı biriktirebilecek arkadaşlarına</b> oluşturduğun çember için davet gönder. </p>
                <img className="highlight-image" src={highlight2} alt="Hep beraber biriktirin" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Biriktirmeye Başla</h1>
                <p>Çemberi oluştururken hedeflediğin sayıda arkadaşın çembere katıldığında çember başlar. 
                  Ödeme alma sırası uygulama tarafından rastgele kura ile belirlenir. 
                  Her ay hedeflenen miktar diğer katılımcılar tarafından <b>ödeme alma sırası gelenin banka hesabına gönderilir, </b>
                  Çember senden para almaz veya hesabından para çekmez. </p>
                <img className="highlight-image" src={highlight3} alt="Hayallerini finanse et!" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={how3} alt="Biriktirmeye Başla"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

      

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-text center">
                <h1>Sen ne için para biriktiriyorsun?</h1>
                <div className="hayaller center">
                    <button>Playstation 5  
                      <span role="img" aria-label="emoji"  className="emoji">
                        🔫
                      </span>
                    </button>
                    <button>Araba 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🚙
                      </span>
                    </button>
                    <button>Paris Tatili 
                      <span role="img" aria-label="emoji"  className="emoji">
                      💭
                      </span>
                    </button>
                    <button>Airpods 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎵
                      </span>
                    </button>
                    <button>Master  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎓
                      </span>
                    </button>
                    <button>Elektrikli Scooter  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛴 
                      </span>
                    </button>
                    <button>iPhone 
                      <span role="img" aria-label="emoji"  className="emoji">
                      📱 
                      </span>
                    </button>
                    <button>Alışveriş 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛒 
                      </span>
                    </button>
                    <button>Dünya Turu 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🌎 
                      </span>
                    </button>
                    <button>Avokado Bahçesi
                      <span role="img" aria-label="emoji"  className="emoji">
                      🥑
                      </span>
                    </button>
                    <button>Düğün
                      <span role="img" aria-label="emoji" className="emoji">
                      👰🤵
                      </span>
                    </button>

                    

                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      </div>
      </div>

    </Layout>
  )
}

export default HowPage